.bgSombreadoTema{background-image:linear-gradient(to right top,#fff,#fff,#edf2ff,#dbe3f6,#dbe3f6)}
.backgroundSectionGray{background-color:#edeeff;border-bottom:2px dashed #111bff24}
button.mat-mdc-menu-trigger.country-selector.mdc-button.mat-mdc-button.mat-unthemed.mat-mdc-button-base{opacity:1!important}
img{height:auto;object-fit:contain}
.modal-dialog{max-width:fit-content;margin-right:auto;margin-left:auto}
/* .modal-sm{max-width:300px} */
.modal-lg,.modal-xl{max-width:800px}
.cdk-overlay-container{z-index:1100}
.min-hauto{
    min-height: auto!important;
}
#project-mcdt #hubspot-messages-iframe-container {
    z-index: 99 !important;
}
@media (min-width:992px){
.modal-lg,.modal-xl{--bs-modal-width:800px}
}
@media (min-width:1200px){
.modal-xl{--bs-modal-width:1140px}
}
@media (max-width:1400px){
.container{max-width:82%!important}
}
@media (max-width:1200px){
.container{max-width:90%!important}
}
@media (max-width:991px){
.container{max-width:91%!important}
}
@media (max-width:880px){
.container{max-width:97%!important}
/* .modal-dialog{margin: auto auto !important;} */
}
@media (max-width:776px){
/* #app-root .bgSombreadoTema { padding-top: 12px !important; } */
#cdk-overlay-0{width:315px!important;height:fit-content;overflow-y:scroll}
.modal-dialog{max-width:100%}
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fade-in {
    animation: fadeIn .5s ease-in-out;
}