.bluedays_lastday_first_slider
    #aplication-tubo-mcdt
        #section-home

            /* .home_last_days_bluedays
                background-image: radial-gradient(circle at 26.37% -55.63%, #4f4e59 0%, #111bff 50%, #050b9b 100%) !important
                background-size: 200% 120%
                animation: moveBackground 15s linear infinite */

            /* .home_last_days_bluedays  
                background-image: url('../assets/images/web/bg-blue-days.png') !important
                background-size: cover !important
                background-repeat: no-repeat !important */
            

            .template_last_bluedays
                background: transparent!important

            #main_nav .navbar-nav .nav-link,
            .icon-user-login i,
            .fullname-user-login
                color: white!important

            #navbar-home #main_nav #config-login-header .nav-link
                color: #1400ff!important

            .slide
                grid-template-columns: 100%!important
            
            .slider-text
                padding: 100px 0 120px !important
                text-align: center

            .title-bluedays 
                text-align: center

            .last_day_description_bluedays 
                text-align: center
                font-size: 16px
                font-weight: 400

            .navigation-slider
                bottom: 4%
                left: 50%
                transform: translate(-50%, -50%)

            .last_bluedays_count 
                display: grid
                grid-template-columns: auto auto auto auto
                justify-content: space-evenly
                align-content: center
                align-items: center
                margin: 85px 20px

                .days::after,
                .hours::after,
                .minutes::after,
                .seconds::after 
                    position: absolute
                    font-size: 15px
                    font-weight: 400
                    top: -22%
                    left: 50%
                    transform: translate(-50%, -50%)

                .days::after
                    content: "Dias"

                .hours::after
                    content: "Horas"

                .minutes::after
                    content: "Minutos"

                .seconds::after
                    content: "Segundos"
                
                

            .last_bluedays_count div 
                padding: 53px 76px
                position: relative
                z-index: 2
                box-shadow: 0px 14px 0px 0px #ffffffc2
                border-radius: 10px
                font-size: 60px
                font-weight: 700
                border: 1px solid white

                .separador-acount 
                    position: absolute
                    right: -33px
                    top: 45%
                    width: 10px
                    height: auto
                    transform: translate(50%, -50%)
                

            
@media (max-width:768px)
    .bluedays_lastday_first_slider 
        #aplication-tubo-mcdt
            #section-home

                .last_bluedays_count
                    display: grid
                    grid-template-columns: 20% 20% 20% 20%
                    margin: 75px 10px 30px
                    justify-content: space-between

                    .days::after,
                    .hours::after,
                    .minutes::after,
                    .seconds::after 
                        position: absolute
                        font-size: 12.5px
                        font-weight: 400
                        top: -30%

                .slider-text 
                    padding: 10px 0 20px !important

                .last_bluedays_count div 
                    padding: 35px 10px
                    box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.1215686275)
                    border-radius: 10px
                    font-size: 45px

                    .separador-acount 
                        right: -21px
                        top: 55%
                        transform: translate(-50%, -50%)
                        height: -webkit-fill-available
                
                .slider
                    padding: 35px 15px 70px 15px
                
                .navigation-slider
                    bottom: 2.5%
                        

@keyframes moveBackground 
  0% 
    background-position: 0% 50%
  
  16.67% 
    background-position: 100% 50%
  
  66.67% 
    background-position: 100% 50%
  
  83.33% 
    background-position: 0% 50%
  
  100% 
    background-position: 0% 100%
  

  
  

  
