.brand_flamingo,
.brand_claudiauribe
    #logo_brand .logo_flamingo,
    .navigation-marca-blanca,
    .brand_footer_marca_blanca
        display: block !important


    #login_component,
    #welcome_component,
    #modal-update-informatio-user
        .filter-brand img
            filter: sepia(80%) saturate(100%) hue-rotate(310deg)


.brand_mcdt
    #logo_brand .logo_mcdt,
    .navigation-mcdt,
    .brand_footer_mcdt
        display: block !important


.brand_mcdt,
.brand_flamingo,
.brand_claudiauribe
    #logo_brand .logo_flamingo img,
    #logo_brand .logo_mcdt img
        width: 220px

@media (max-width:767px)
    .brand_mcdt,
    .brand_flamingo,
    .brand_claudiauribe
        #logo_brand .logo_flamingo img,
        #logo_brand .logo_mcdt img
            width: 170px