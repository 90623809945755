i.mi {
  display: inline-block;
  width: 50px;
  height: 50px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

i.mi-filter{
  width: 26px;
  height: 18px;
}

i.mi-ty {
  width: 15px;
  height: 15px;
}

i.mi-sm {
  width: 25px;
  height: 25px;
}

i.mi-md {
  width: 75px;
  height: 75px;
}

i.mi-lg {
  width: 125px;
  height: 125px;
}

i.mi-xl {
  width: 175px;
  height: 175px;
}

i.mi-payment-with-link{
  background-image: url('../mejorcdt-icons/payment_with_link.svg');
}

i.mi-abre-cdt-check{
  background-image: url('../mejorcdt-icons/abre-cdt-check.svg');
}

i.mi-recarga-cdt{
  background-image: url('../mejorcdt-icons/recarga-cdt.svg');
}

i.mi-compara-cdts{
  background-image: url('../mejorcdt-icons/compara-cdts.svg');
}

i.mi-retirar-dinero-vencimiento{
  background-image: url('../mejorcdt-icons/retirar-dinero-vencimiento.svg');
}

i.mi-identity-validation{
  background-image: url('../mejorcdt-icons/icon-identity-validation.svg');
}

i.mi-chronometer{
  background-image: url('../mejorcdt-icons/chronometer.svg');
}

i.mi-sort-filter{
  background-image: url('../mejorcdt-icons/sort-filter.svg');
}

i.mi-exclamation-triangle {
  background-image: url('../mejorcdt-icons/exclamation-triangle.svg');
}

i.mi-bell {
  background-image: url('../mejorcdt-icons/bell.svg');
}

i.mi-check-circle {
  background-image: url('../mejorcdt-icons/check-circle.svg');
}

i.mi-chat-dots {
  background-image: url('../mejorcdt-icons/chat-dots.svg');
}

i.mi-comments-dots {
  background-image: url('../mejorcdt-icons/comments-dots.svg');
}

i.mi-download-app {
  background-image: url('../mejorcdt-icons/download-app.svg');
}

i.mi-check-stars {
  background-image: url('../mejorcdt-icons/check-stars.svg');
}

i.mi-percent-starts {
  background-image: url('../mejorcdt-icons/percent-starts.svg');
}

i.mi-bank-percent {
  background-image: url('../mejorcdt-icons/bank-percent.svg');
}

i.mi-clock {
  background-image: url('../mejorcdt-icons/clock.svg');
}

i.mi-calendar {
  background-image: url('../mejorcdt-icons/calendar.svg');
}

i.mi-calendar-coin {
  background-image: url('../mejorcdt-icons/calendar-coin.svg');
}

i.mi-display-chart-up-coin {
  background-image: url('../mejorcdt-icons/display-chart-up-coin.svg');
}

i.mi-document-chart-up-coin {
  background-image: url('../mejorcdt-icons/document-chart-up-coin.svg');
}

i.mi-calculator {
  background-image: url('../mejorcdt-icons/calculator.svg');
}

i.mi-coin {
  background-image: url('../mejorcdt-icons/coin.svg');
}

i.mi-coin-plus {
  background-image: url('../mejorcdt-icons/coin-plus.svg');
}

i.mi-plant-coin {
  background-image: url('../mejorcdt-icons/plant-coin.svg');
}

i.mi-moneybag-plant {
  background-image: url('../mejorcdt-icons/moneybag-plant.svg');
}

i.mi-hand-coins {
  background-image: url('../mejorcdt-icons/hand-coins.svg');
}

i.mi-hand-plant-coin {
  background-image: url('../mejorcdt-icons/hand-plant-coin.svg');
}

i.mi-coin-bank {
  background-image: url('../mejorcdt-icons/coin-bank.svg');
}

i.mi-sign-documents {
  background-image: url('../mejorcdt-icons/sign-documents.svg');
}

i.mi-documents-ready {
  background-image: url('../mejorcdt-icons/documents-ready.svg');
}

i.mi-check-document {
  background-image: url('../mejorcdt-icons/check-document.svg');
}

i.mi-identification-document {
  background-image: url('../mejorcdt-icons/identification-document.svg');
}

i.mi-hand-bank-check {
  background-image: url('../mejorcdt-icons/hand-bank-check.svg');
}

i.mi-bank-moneybag {
  background-image: url('../mejorcdt-icons/bank-moneybag.svg');
}

i.mi-secure-moneybag {
  background-image: url('../mejorcdt-icons/secure-moneybag.svg');
}

i.mi-costumer-care {
  background-image: url('../mejorcdt-icons/costumer-care.svg');
}

i.mi-rechargeable-cdt {
  background-image: url('../mejorcdt-icons/rechargeable-cdt.svg');
}

i.mi-download-newsletter {
  background-image: url('../mejorcdt-icons/download-newsletter.svg');
}

i.mi-support {
  background-image: url('../mejorcdt-icons/support.svg');
}

i.mi-blue-alert {
  background-image: url('../mejorcdt-icons/blue-icons/alert.svg');
}

i.mi-blue-celebration {
  background-image: url('../mejorcdt-icons/blue-icons/celebration.svg');
}

i.mi-blue-hand-coins {
  background-image: url('../mejorcdt-icons/blue-icons/hand-coins.svg');
}

i.mi-blue-megaphone {
  background-image: url('../mejorcdt-icons/blue-icons/megaphone.svg');
}

i.mi-blue-mobile-phone {
  background-image: url('../mejorcdt-icons/blue-icons/mobile-phone.svg');
}

i.mi-blue-plane {
  background-image: url('../mejorcdt-icons/blue-icons/plane.svg');
}

i.mi-blue-portfolio-money {
  background-image: url('../mejorcdt-icons/blue-icons/portfolio-money.svg');
}

i.mi-blue-tv {
  background-image: url('../mejorcdt-icons/blue-icons/tv.svg');
}

i.mi-blue-users {
  background-image: url('../mejorcdt-icons/blue-icons/users.svg');
}

i.mi-blue-web {
  background-image: url('../mejorcdt-icons/blue-icons/web.svg');
}
