.brand_claudiauribe 
  --color-white: #FFFFFF
  --color-blue-10: #F1F1FF
  --color-blue-20: #eef9fd
  --color-blue-40: #e6f8ff
  --color-blue-60: #107093 
  --color-blue-100: #083444 
  --color-blue-150: #042734
  --color-blue-180: #011921
  --color-black-10: #D3D3D3
  --color-black-50: #898989
  --color-black-100: #363636
  --color-red: #FF5050
  --color-green: #fc6622
  --color-aquamarine: #00C2FF
  --color-yellow: #FFD600
