.water-container {
    position: relative;
    width: 200px;
    height: 200px;
    background-color: #0099cc;
    overflow: hidden;
  }
  
  .water {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px;
    background-color: #66ccff;
    animation: waterAnimation 2s infinite cubic-bezier(0.4, 0.0, 0.2, 1);
  }
  
  @keyframes waterAnimation {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
  }