.load-data-gif{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);z-index:9}
.ng-failed-lazyloaded,.ng-lazyloading{width:20%}
.ng-lazyloaded{width:auto}
.disabled{opacity:.3;cursor:no-drop}
.backgroundSectionGray{background-color:#edeeff;border-bottom:2px dashed #111bff24}
.bi-x-lg{-webkit-text-stroke:3px}
.justify-items-end{justify-items:end}
.d-content{display:contents}
.slide-down{animation:slideIn .5s ease-in-out}
.slide-up{animation:slideOut .5s ease-in-out}
.modal-container-personalizado{position:fixed;top:0;left:0;width:100%;height:100%;background-color:rgba(0,0,0,.5);display:flex;align-items:center;justify-content:center;z-index:9999}
.modal-container-personalizado .modal-body{background-color:#fff;padding:20px;max-width:633px;border-radius:4px;box-shadow:0 2px 6px rgba(0,0,0,.3)}
.font-Montserrat{font-family:Montserrat,sans-serif}
.font-revert-mcdt{font-family:revert}
.border_bottom_flamingo{border-bottom:5px dotted #cf080f1a}
.border_bottom_mcdt{border-bottom:5px dotted #111bff42}
.btn_actions_mcdt{transition:all .5s;background:#111bff;color:#fff}
.btn_actions_flamingo{transition:all .5s;background:#ff0050;color:#fff}
.btn_actions_mcdt:hover{transition:all .5s;background:#1203b4;box-shadow:0 0 13px #00000096}
.btn_actions_flamingo:hover{transition:all .5s;background:#b30d15;box-shadow:0 0 13px #b30d1543}
.border_file_flamingo{border:1px solid #ff0050!important}
.border_file_mcdt{border:1px solid #111bff!important}
.btn_bg_hover_flamingo:hover{background:#ff0050!important;transition:all .5s;color:#fff!important}
.btn_bg_hover_mcdt:hover{background:#111bff!important;transition:all .5s;color:#fff!important}
.width_logo_marca_blanca{width:250px}
.width_logo_mcdt{width:200px; height: 50px !important;}
.font_size_h1_flamingo{font-size:40px}
.font_size_h1_mcdt{font-size:40.5px}
.color-flamingo{color:#cf080f!important}
.color-flamingo-secundario{color:#ef1c21!important}
.color-mcdt,.color-mcdt-primary{color:#111bff!important}
.color-mcdt-secundario{color:#0d7bee}
.border_rigth_mcdt{border-right:6px dotted #0f46e454}
.border_rigth_flamingo{border-right:0}
.bg_shadow_mcdt{box-shadow:0 0 20px -3px #0100ff52}
.bg_shadow_flamingo{border:1px solid #e1e1e1}
.background_color_tema_mcdt,.bgColorTheme{background-color:#111bff!important}
.background_color_tema_flamingo{background-color:#ef1c21!important}
.btn_color_form_mcdt{background-color:#111bff!important}
.btn_color_flamingo,.btn_color_form_flamingo{background:#cf080f!important;font-weight:400!important;border-radius:35px!important}
.btn_color_mcdt{background:#00db2f;transition:.5s}
.btn_color_mcdt:hover{box-shadow:0 0 13px #00000096;transition:.5s}
.btn_color_flamingo:hover,.btn_color_form_flamingo:hover{background:#ff0008!important}
.border_botom_form_flamingo{border-bottom:1px solid #ff0008}
.border_botom_form_mcdt{border-bottom:1px solid #111bff}
.text-justify{text-align:justify}
.cursor-pointer{cursor:pointer}
.screenBloqe{position:absolute;z-index:999;top:50%;left:50%;transform:translate(-50%,-50%)}
.color-secondary-mcdt{color:#0bb0ff}
.colorAmount{color:#5ac62d}
.color-text1-ban100{color:#250e62}
.color-text2-ban100{color:#7845ec}
.border-radius-top-left-td{border-top-left-radius:15px}
.border-radius-top-right-td{border-top-right-radius:15px}
.border-radius-bottom-left-td{border-bottom-left-radius:15px}
.border-radius-bottom-right-td{border-bottom-right-radius:15px}
.colorInfluencer{color:#ff8605!important}
.color-mcdt-primary3,.colorTema3{color:#00adff!important}
/* .modal-content{border-radius:20px!important} */
.boxShadowHover{transition:all .2s}
.boxShadowHover:hover{background:#111bff!important;box-shadow:3px -3px 5px 1px #0000005c;transition:all .2s;color:#fff!important}
.fs-ngxInternational{font-size:18px}
.sms_digit_login{width:28px!important;height:30px!important}
.ngotplogin div{background:#fff;padding:10px 8px;box-shadow:0 0 3px 1px #cecece;border-radius:6px}
.is-invalid-otp div,.is-invalid-otp div input{border:1px solid #fa0000!important}
.is-invalid-otp div input{background-color:#f99797}
.is-valid-otp div input{background-color:#dbe3fb}
.boxShadowHover:hover{transform:translateY(-2px);box-shadow:3px 3px 6px 0 #00000069;transition:all .2s}
.boxShadowHover{transition:all .2s}
.justify-items-center{justify-items:center}
.iti{width:100%}
#phone{width:100%}
.input_national{width:78%;border:2px solid #142cb1;border-radius:7px;color:#142cb1!important;font-weight:700;font-size:25px;transition:all .5s}
.sms_digit{color:#1b41b1}
.sms_digit:focus,.sms_digit:focus-visible{border:2px solid #1b41b1!important;outline:0}
.max-width-app,.modal-auto-fit{max-width:fit-content!important}
.sms_digit_dashboard{width:14%!important}
@keyframes slideIn{
0%{max-height:0;opacity:0}
100%{max-height:1000px;opacity:1}
}
@keyframes slideOut{
0%{max-height:1000px;opacity:1}
100%{max-height:0;opacity:0}
}
@media (min-width:576px){
.paddingModal{padding:auto}
}
@media (max-width:1400px){
#app-root{zoom:.8}
#aplication-tubo-mcdt .modal-backdrop{width:100%;height:100%}
}
@media (min-width:768px){
.paddingModal{padding:0 120px}
}
@media (max-width:768px){
.bg-mobile-steep{background:#d6dffac7}
#app-root{zoom:.95}
.sms_digit_login{width:30px!important;font-size:16px!important;height:30px!important}
.width_logo_mcdt{}
}
button.mat-mdc-menu-trigger.country-selector.mdc-button.mat-mdc-button.mat-unthemed.mat-mdc-button-base{opacity:1!important}