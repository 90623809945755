.tooltip-mcdt
    position: absolute
    z-index: 1000
    width: 250px
    top: 24px
    background-color: var(--color-blue-20)
    color: var(--color-blue-180)
    padding: 20px 15px
    font-size: 14px
    max-width: 250px
    text-align: left
    border-radius: 10px

    &.top
        transform: translateX(-50%)

    &.bottom
        transform: translateX(-50%) translateY(10px)

    &.left
        transform: translateY(-50%) translateX(-100%)

    &.right
        transform: translateY(-50%) translateX(10px)

    @media (max-width: 770px)
        line-height: 16px

        &.top
            transform: none
